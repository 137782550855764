import { FC, useState } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useInView } from 'react-intersection-observer';
import { NavIcon } from './NavIcon';
import { Menu, Transition } from '@headlessui/react';
import { NextNavLink } from './NextNavLink';
import { NavItem } from './NavItem';
import styles from './NavHeader.module.css';

const items = [
  { href: '/features', title: 'Features' },
  { href: '/how-it-works', title: 'How It Works' },
  { href: '/learn', title: 'Learn' },
  { href: 'https://www.life.church/careers/team/?name=Digital%20Technology', title: 'Careers' },
  { href: '/login', title: 'Log In' },
];

const DARK_MODE_ROUTES = ['/'];

const NavHeader: FC = () => {
  const { ref, inView } = useInView({
    threshold: 1,
  });
  const [navOpen, setNavOpen] = useState<boolean>(false);
  const router = useRouter();
  const isDarkMode = DARK_MODE_ROUTES.includes(router.pathname);
  const bgColor = isDarkMode ? 'bg-black' : 'bg-white';
  const bgOpacity = inView ? 'bg-opacity-0' : 'bg-opacity-95';
  const bgSpacing = inView ? styles['bg-size'] : 'mt-8 pb-2 pt-8 sm:pb-6';
  const bgTransition = navOpen ? '' : 'transition duration-150 ease-in-out';
  const logoSrc = isDarkMode
    ? '/images/global/church_online_platform_logo_light.png'
    : '/images/global/church_online_platform_logo_dark.png';

  const handleBgOpacity = () => {
    navOpen ? setNavOpen(false) : setNavOpen(true);
  };

  const navClass = classNames(
    bgColor,
    bgOpacity,
    bgTransition,
    'sticky',
    '-top-px',
    'py-3',
    'px-4',
    'flex',
    'items-center',
    'w-full',
    'h-24',
    'z-30'
  );

  const btnClass = classNames('btn', 'btn-secondary', {
    'on-dark': isDarkMode,
  });

  return (
    <>
      <nav ref={ref} className={navClass}>
        <div className='flex items-center w-full mx-auto max-w-screen-xl'>
          <div className='absolute z-40 top-6'>
            <Link href='/'>
              <a className=''>
                <Image src={logoSrc} width={202} height={32} alt='Church Online Platform Logo' />
              </a>
            </Link>
          </div>
          <div className='hidden ml-auto lg:flex'>
            {items.map(item => (
              <NextNavLink key={item.href} href={item.href}>
                {item.title}
              </NextNavLink>
            ))}
            <div className='md:hidden'>
              <Link href='/signup'>
                <a className={btnClass}>Sign Up</a>
              </Link>
            </div>
          </div>
          <div className='ml-auto lg:hidden' onClick={handleBgOpacity}>
            <Menu>
              {({ open }) => (
                <>
                  <Menu.Button className='absolute top-0 right-0 z-40 mt-8 mr-4 cursor-auto focus:outline-none sm:mr-32 sm:pr-2.5'>
                    <NavIcon isOpen={open} isDarkMode={isDarkMode} />
                  </Menu.Button>
                  <div className=''>
                    <Transition
                      show={open}
                      enter='transition-opacity duration-150'
                      enterFrom='opacity-0'
                      enterTo='opacity-100'
                      leave='transition-opacity duration-150'
                      leaveFrom='opacity-100'
                      leaveTo='opacity-0'
                    >
                      <Menu.Items
                        static
                        className={`absolute left-0 w-full ${bgSpacing} flex flex-col justify-center space-y-5 sm:space-y-0 text-center ${bgColor} bg-opacity-95 sm:flex-row focus:outline-none`}
                      >
                        {items.map(item => (
                          <NavItem key={item.href} href={item.href}>
                            {item.title}
                          </NavItem>
                        ))}
                        <div className='pb-8 mx-auto sm:hidden'>
                          <Link href='/signup'>
                            <a className={btnClass}>Sign Up</a>
                          </Link>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </div>
                </>
              )}
            </Menu>
          </div>
          <div className='absolute top-0 right-0 z-40 hidden mr-4 mt-7 sm:block lg:static lg:mt-0 lg:ml-4'>
            <Link href='/signup'>
              <a className={btnClass}>Sign Up</a>
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export { NavHeader };
