import { FC } from 'react';

interface Props {
  className?: string;
}

const TimesIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      className={`fill-current h-8 w-8 ${className}`.trim()}
    >
      <path
        d='M16 14.5858L25.2929 5.29289L26.7071 6.70711L17.4142 16L26.7071 25.2929L25.2929 26.7071L16 17.4142L6.70712 26.7071L5.29291 25.2929L14.5858 16L5.29291 6.70711L6.70712 5.29289L16 14.5858Z'
        fillRule='evenodd'
        clipRule='evenodd'
      />
    </svg>
  );
};

export { TimesIcon };
