import { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import Page from '../components/Page';
import { NextPageContext } from 'next';
import '@lifechurch/twion/styles.css';
import '../app.css';
import { useRouter } from 'next/dist/client/router';
import { ReactElement } from 'react';

type ErrType = Pick<NextPageContext, 'err'>;

interface Props {
  err: ErrType;
}

function MyApp({ Component, pageProps, err }: AppProps & Props): ReactElement {
  const router = useRouter();
  return (
    <>
      <Script strategy='lazyOnload' src='https://fast.wistia.com/assets/external/E-v1.js' />

      <Script
        dangerouslySetInnerHTML={{
          __html: `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GA_TRACKING_ID}');
      `,
        }}
      />
      <Head>
        <link rel='canonical' href={`https://churchonlineplatform.com${router.asPath}`} />
        <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
        <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
        <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#FF9000' />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff' />
      </Head>
      <Page>
        <Component {...pageProps} err={err} />
      </Page>
    </>
  );
}

export default MyApp;
