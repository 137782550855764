import { FC, Fragment, ReactNode } from 'react';
import { Menu } from '@headlessui/react';
import { NextNavLink } from './NextNavLink';

interface Props {
  href: string;
  children: ReactNode;
}

export const NavItem: FC<Props> = ({ href, children }) => {
  return (
    <div className='flex content-center justify-center'>
      <Menu.Item as={Fragment}>
        <NextNavLink href={href}>{children}</NextNavLink>
      </Menu.Item>
    </div>
  );
};
