import { FC } from 'react';
import { BarsIcon } from './BarsIcon';
import { TimesIcon } from './TimesIcon';

interface Props {
  isOpen: boolean;
  isDarkMode: boolean;
}

const NavIcon: FC<Props> = ({ isOpen, isDarkMode }) => {
  const color = isDarkMode ? 'text-white' : 'text-gray-30';
  const hoverColor = isDarkMode ? 'hover:text-white' : 'hover:text-gray-100';
  if (isOpen) {
    return <TimesIcon className={`${color} ${hoverColor}`} />;
  } else {
    return <BarsIcon className={`${color} ${hoverColor}`} />;
  }
};

export { NavIcon };
