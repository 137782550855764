import { FC, ReactNode, useMemo } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

interface Props {
  href: string;
  children: ReactNode;
}

const DARK_MODE_ROUTES = ['/'];

// Ensure all props are forwarded to the anchor directly from Menu.Item
export const NextNavLink: FC<Props> = ({ href, children, ...rest }) => {
  const router = useRouter();
  const isDarkMode = DARK_MODE_ROUTES.includes(router.pathname);
  const isActive = useMemo(
    () => `/${router.pathname.split('/')[1]}` === href,
    [href, router.pathname]
  );
  const unfocusedTextColor = isDarkMode ? 'text-gray-10' : 'text-gray-80';
  const focusedTextColor = isDarkMode ? 'text-white' : 'text-gray-100';
  const textColor = isActive ? focusedTextColor : unfocusedTextColor;
  const textColorHover = isDarkMode ? 'hover:text-white' : 'hover:text-gray-100';
  const target = href.startsWith('http') ? '_blank' : '_self';

  return (
    <>
      <style jsx>{`
        .nav-link::after {
          content: '';
          display: block;
          width: 75%;
          margin: 0 auto;
          border-bottom: 2px solid ${isActive ? '#ff9000' : 'transparent'};
        }
        .nav-link:hover::after {
          border-bottom-color: #9f9fa0;
        }
      `}</style>
      <Link href={href}>
        <a
          {...rest}
          className={`nav-link ${textColor} ${textColorHover} hover:no-underline font-semibold leading-normal px-4`}
          target={target}
        >
          {children}
        </a>
      </Link>
    </>
  );
};
