import { FC } from 'react';

interface Props {
  className?: string;
}

const BarsIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      className={`fill-current h-8 w-8 ${className}`.trim()}
    >
      <path
        d='M5 8H27V10H5V8ZM5 22H27V24H5V22ZM27 15H5V17H27V15Z'
        fillRule='evenodd'
        clipRule='evenodd'
      />
    </svg>
  );
};

export { BarsIcon };
