import { FC } from 'react';
import Head from 'next/head';
import { hotjar } from 'react-hotjar';

const Meta: FC = () => (
  <Head>
    <title>Church Online Platform</title>
    <meta
      name='viewport'
      content='width=device-width, maximum-scale=1.0, initial-scale=1.0, user-scalable=no'
    />
    <meta charSet='utf-8' />
    {process.env.NEXT_PUBLIC_APP_ENV !== 'production' && <meta name='robots' content='noindex' />}
    <link rel='shortcut icon' href='/static/favicon.ico' />
    {process.browser &&
      process.env.NEXT_PUBLIC_APP_ENV === 'production' &&
      hotjar.initialize(process.env.NEXT_PUBLIC_HOTJAR_ID as unknown as number, 6)}
  </Head>
);

export default Meta;
