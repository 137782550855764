import { AlertType } from '../lib/alertContext';
import { ErrorAlert, InfoAlert, SuccessAlert } from '@lifechurch/react-ion';
import { FC } from 'react';

interface AlertProps {
  uniqueKey: string;
  type: AlertType;
  onDismiss?: () => void;
  fuseDisabled?: boolean;
  fuseTimer?: number;
}

const Alert: FC<AlertProps> = ({
  uniqueKey,
  type,
  onDismiss,
  children,
  fuseDisabled,
  fuseTimer,
}) => {
  switch (type) {
    case AlertType.INFO:
      return (
        <InfoAlert
          uniqueKey={uniqueKey}
          onDismiss={onDismiss}
          fuseDisabled={fuseDisabled}
          fuseTimer={fuseTimer}
        >
          {children}
        </InfoAlert>
      );
    case AlertType.SUCCESS:
      return (
        <SuccessAlert
          uniqueKey={uniqueKey}
          onDismiss={onDismiss}
          fuseDisabled={fuseDisabled}
          fuseTimer={fuseTimer}
        >
          {children}
        </SuccessAlert>
      );
    case AlertType.ERROR:
      return (
        <ErrorAlert
          uniqueKey={uniqueKey}
          onDismiss={onDismiss}
          fuseDisabled={fuseDisabled}
          fuseTimer={fuseTimer}
        >
          {children}
        </ErrorAlert>
      );
    default:
      return null;
  }
};

export default Alert;
