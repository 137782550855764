import { createContext, FC, ReactNode, useContext, useReducer } from 'react';
import invariant from 'tiny-invariant';
import Alert from '../components/Alert';
import { AlertContainer } from '@lifechurch/react-ion';
import { v4 as uuidv4 } from 'uuid';

export enum AlertType {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

type Message = ReactNode | string;

interface AlertContextInterface {
  openAlert: (type: AlertType, message: Message) => void;
  alerts: JSX.Element[];
}

interface AlertState {
  alerts: JSX.Element[];
}

interface ReducerAction {
  type: string;
  alert?: JSX.Element;
  uniqueKey?: string;
}

const AlertContext = createContext<AlertContextInterface>({} as AlertContextInterface);

export const AlertProvider: FC = ({ children }) => {
  const reducer = (state: AlertState, action: ReducerAction): AlertState => {
    switch (action.type) {
      case 'add':
        if (!action.alert) return { alerts: [...state.alerts] };
        return { alerts: [action.alert, ...state.alerts] };
      case 'remove':
        return {
          alerts: [...state.alerts.filter(alert => alert.props.uniqueKey !== action.uniqueKey)],
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, { alerts: [] });

  const onDismiss = (uniqueKey: string): void => {
    dispatch({ type: 'remove', uniqueKey: uniqueKey });
  };

  const openAlert = (type: AlertType, message: Message): void => {
    const uniqueKey = uuidv4();
    const alert = (
      <Alert
        key={uniqueKey}
        uniqueKey={uniqueKey}
        type={type}
        onDismiss={() => onDismiss(uniqueKey)}
      >
        {message}
      </Alert>
    );

    dispatch({ type: 'add', alert: alert });
  };

  const value = {
    openAlert,
    alerts: [...state.alerts],
  };

  return (
    <AlertContext.Provider value={value}>
      <AlertContainer>{state.alerts}</AlertContainer>
      {children}
    </AlertContext.Provider>
  );
};

export const useAlertContext = (): AlertContextInterface => {
  const context = useContext(AlertContext);
  invariant(
    context && Object.prototype.hasOwnProperty.call(context, 'alerts'),
    'useAlertContext must be used within a AlertProvider'
  );
  return context;
};
