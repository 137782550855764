import Meta from './Meta';
import { FC } from 'react';
import { AlertProvider } from '../lib/alertContext';
import { NavHeader } from './navigation';
import { Footer } from './footer';

const Page: FC = ({ children }) => (
  <>
    <Meta />
    <NavHeader />
    <AlertProvider>
      <div className='overflow-hidden' style={{ minHeight: 'calc(100vh - 152px)' }}>
        <div className='mx-4 sm:mx-8'>{children}</div>
      </div>
    </AlertProvider>
    <Footer />
  </>
);

export default Page;
