import { FC } from 'react';
import Link from 'next/link';
import TwitterLogo from '../../components/icons/Twitter.svg';
import { useRouter } from 'next/dist/client/router';

const items = [
  {
    children: (
      <>
        <TwitterLogo className='fill-current' />
        @COPlatform
      </>
    ),
    href: 'https://twitter.com/COPlatform',
  },
  { children: 'About', href: '/about' },
  { children: 'Support', href: 'https://support.online.church' },
  { children: 'Privacy', href: '/privacy' },
  { children: 'Terms', href: '/terms' },
];

const Footer: FC = () => {
  const router = useRouter();
  const isHomePage = router.asPath === '/';

  return (
    <footer className='bg-gray-5'>
      <div className='flex flex-col p-8 mx-auto text-center max-w-7xl lg:flex-row'>
        <nav className='flex flex-col justify-center mb-6 text-center space-y-3 md:flex-row md:space-x-5 md:space-y-0 lg:mb-0'>
          {items.map(item => {
            return (
              <Link key={item.href} href={item.href}>
                <a className='flex items-center justify-center underline text-gray-50 hover:text-primary-hover'>
                  {item.children}
                </a>
              </Link>
            );
          })}
        </nav>
        <div className='lg:ml-auto'>
          <p className='text-gray-50'>
            &copy; {new Date().getFullYear()}{' '}
            <a className='hover:text-primary-hover' href='https://www.life.church' rel='noopener'>
              Life.Church
            </a>
            {' / '}
            <a className='hover:text-primary-hover' href='https://bible.com' rel='noopener'>
              YouVersion
            </a>
            {'  '}
            All rights reserved
          </p>
          {isHomePage && (
            <p className='mt-2 text-gray-50'>
              Structured content powered by{' '}
              <a
                className='underline hover:text-primary-hover'
                href='https://www.sanity.io'
                target='_blank'
                rel='noopener noreferrer'
              >
                Sanity.io
              </a>
            </p>
          )}
        </div>
      </div>
    </footer>
  );
};

export { Footer };
